/* Custom CSS rules that add to Bulma's styles for the header element */
header {
    /* linear-gradient(rgba(240,240,240,0.5), rgba(240,240,240,0.5)) */
    background: {
        image: url(/assets/images/header_books.jpg);
        repeat: no-repeat;
        size: cover;
        position: top center;
    }
}
header h1 {
    font-family: 'Vesper Libre', serif;
    text-shadow: 4px 4px 2px #000, 6px 6px 2px #000, 8px 8px 2px #000;
}