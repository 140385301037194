@import "bulma/sass/utilities/functions.sass"; //Necessary for the findColorInvert function

/* Bulma font customizations */
$family-serif: 'Crimson Pro', serif;
$family-monospace: 'Inconsolata', monospace;
$family-primary: $family-serif;
$family-secondary: 'Podkova', serif;
$body-size: 20px;
$title-family: $family-secondary;
$subtitle-family: $family-secondary;

/* Bulma color customizations */
/* The "bis" and "ter" suffixes in Bulma have no clear meaning and aren't 
   standard color terms. I'm guessing they mean "a little darker" and "a bit 
   more dark" based on their usage, so I tried making "bis" and "ter" versions
   of my custom colors by slightly decreasing the brightness of the main color.
 */
$brown: #5C3720;
$brown-bis: #54321D;
$brown-ter: #4D2E1B;
$paper: #e7e0d8;
$paper-bis: #DBD5CE;
$paper-ter: #CFC9C2;
$deepblue: #2A63A8;
$darkred: #6B1F1F;
$brownred: #733222;
$brownred-light: #BF5439;

$scheme-main: $paper;
$scheme-main-bis: $paper-bis;
$scheme-main-ter: $paper-ter;
$scheme-invert: findColorInvert($scheme-main);
$body-background-color: $brown;

$info: $deepblue;
$primary: $darkred;
$primary-invert: findColorInvert($primary);
$footer-background-color: $primary;
$footer-color: $primary-invert;
//Annoyingly, I have to re-define $black to its default value in order to
//use it, because none of Bulma's variables are imported yet
$black: hsl(0, 0%, 4%);
$text: $black;
$text-strong: $black;

$link-hover: $brownred;
$link-hover-border: $brownred-light;
$link-focus: $brownred;
$link-active: $brownred;
$link-active-border: $brownred;