$progress-bar-background-color: $border-light !default;
$progress-value-background-color: $text !default;
$progress-border-radius: $radius-rounded !default;

$progress-indeterminate-duration: 1.5s !default;

$progress-colors: $colors !default;

.progress {
  @extend %block;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: $progress-border-radius;
  display: block;
  height: $size-normal;
  overflow: hidden;
  padding: 0;
  width: 100%;
  &::-webkit-progress-bar {
    background-color: $progress-bar-background-color; }
  &::-webkit-progress-value {
    background-color: $progress-value-background-color; }
  &::-moz-progress-bar {
    background-color: $progress-value-background-color; }
  &::-ms-fill {
    background-color: $progress-value-background-color;
    border: none; }
  // Colors
  @each $name, $pair in $progress-colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      &::-webkit-progress-value {
        background-color: $color; }
      &::-moz-progress-bar {
        background-color: $color; }
      &::-ms-fill {
        background-color: $color; }
      &:indeterminate {
        background-image: linear-gradient(to right, $color 30%, $progress-bar-background-color 30%); } } }

  &:indeterminate {
    animation-duration: $progress-indeterminate-duration;
    animation-iteration-count: infinite;
    animation-name: moveIndeterminate;
    animation-timing-function: linear;
    background-color: $progress-bar-background-color;
    background-image: linear-gradient(to right, $text 30%, $progress-bar-background-color 30%);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 150% 150%;
    &::-webkit-progress-bar {
      background-color: transparent; }
    &::-moz-progress-bar {
      background-color: transparent; }
    &::-ms-fill {
      animation-name: none; } }

  // Sizes
  &.is-small {
    height: $size-small; }
  &.is-medium {
    height: $size-medium; }
  &.is-large {
    height: $size-large; } }

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0; }
  to {
    background-position: -200% 0; } }
