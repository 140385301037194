/* Classes to help size and position groups of images */
.largePicture {
	width: 500px;
}
.smallPicture {
	width: 350px;
	padding: 2px;
}

.imgBox {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	text-align: center;
}