.has-background-texture {
	background-image: url(/assets/images/white-parchment-paper.jpg);
	/* Fallback color similar to the texture's main color */
	background-color: $paper;
}

.partitle {
	font-weight: bold;
}

.toc-title {
	margin-bottom: 0.5rem !important;
}
.toc-list {
	padding-left: 1.2rem;
	li {
		padding: 0.25rem 0;
	}
}

.tight-list p {
    margin: .25rem 0;
}
